import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Privacy Notice' />
    <Divider />
    <Stack>
      <Main>
        <h1>Cookies and other technologies we use</h1>
        <p>
          We use cookies and/or similar technologies to analyze customer
          behavior, administer the website, track users' movements, and to
          collect information about users. This is done in order to personalize
          and enhance your experience with us.
        </p>
        <p>
          A cookie is a tiny text file stored on your computer. Cookies store
          information that is used to help make sites work. Only we can access
          the cookies created by our website. You can control your cookies at
          the browser level. Choosing to disable cookies may hinder your use of
          certain functions.
        </p>
        <p>
          <strong>We use cookies for the following purposes:</strong>
        </p>
        <ul>
          <li>
            Necessary cookies - these cookies are required for you to be able to
            use some important features on our website, such as logging in.
            These cookies don't collect any personal information.
          </li>
          <li>
            Functionality cookies - these cookies provide functionality that
            makes using our service more convenient and makes providing more
            personalized features possible. For example, they might remember
            your name and e-mail in comment forms so you don't have to re-enter
            this information next time when commenting.
          </li>
          <li>
            Analytics cookies - these cookies are used to track the use and
            performance of our website and services
          </li>
          <li>
            Advertising cookies - these cookies are used to deliver
            advertisements that are relevant to you and to your interests. In
            addition, they are used to limit the number of times you see an
            advertisement. They are usually placed to the website by advertising
            networks with the website operator's permission. These cookies
            remember that you have visited a website and this information is
            shared with other organizations such as advertisers. Often targeting
            or advertising cookies will be linked to site functionality provided
            by the other organization.
          </li>
        </ul>
        <p>
          <strong>Here are the cookies we use:</strong>
        </p>
        <ul>
          <li>
            Cookie policy - persistent cookie - 1-year duration - we set this
            cookie to limit showing the cookie policy on every page view/visits.
          </li>
          <li>
            <a
              href='https://google.com/analytics/'
              target='_blank'
              rel='noreferrer'
            >
              Google Analytics
            </a>{' '}
            - persistent cookie - 2-year duration - we use Google Analytics to
            improve the user experience on the website (
            <a
              href='https://tools.google.com/dlpage/gaoptout'
              target='_blank'
              rel='noreferrer'
            >
              you can opt out here
            </a>
            )
          </li>
          <li>
            Popup cookie - persistent cookie - 1-year duration - we offer you
            the chance to be notified of new content and marketing materials
          </li>
        </ul>
        <p>
          You can remove cookies stored in your computer via your browser
          settings. Alternatively, you can control some 3rd party cookies by
          using a privacy enhancement platform such as{' '}
          <a
            href='http://optout.aboutads.info/#!/'
            target='_blank'
            rel='noreferrer'
          >
            optout.aboutads.info
          </a>{' '}
          or{' '}
          <a
            href='http://www.youronlinechoices.com/'
            target='_blank'
            rel='noreferrer'
          >
            youronlinechoices.com
          </a>
          . For more information about cookies, visit{' '}
          <a
            href='http://www.allaboutcookies.org/'
            target='_blank'
            rel='noreferrer'
          >
            allaboutcookies.org
          </a>
          .
        </p>
        <p>
          We use Google Analytics to measure traffic on our website. Google has
          their own Privacy Policy which you can review{' '}
          <a
            href='https://support.google.com/analytics/answer/6004245'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          . If you'd like to opt out of tracking by Google Analytics, visit the{' '}
          <a
            href='https://tools.google.com/dlpage/gaoptout'
            target='_blank'
            rel='noreferrer'
          >
            Google Analytics opt-out page
          </a>
          .
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
